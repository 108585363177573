import { Heading } from "native-base";
import React from "react";

const FormHeader = ({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
}) => (
  <>
    <Heading
      mt={["100", "6"]}
      alignSelf="center"
      size="lg"
      fontWeight="600"
      color="coolGray.800"
      _dark={{
        color: "warmGray.50",
      }}
    >
      {title}
    </Heading>
    <Heading
      alignSelf="center"
      mt="1"
      _dark={{
        color: "warmGray.200",
      }}
      color="coolGray.600"
      fontWeight="medium"
      size="xs"
    >
      {subTitle}
    </Heading>
  </>
);

export default FormHeader;
