import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Routes, UserRoles } from "../../utils/constants";
import SignIn from "../signIn/SignIn";
import { RootStackParamList } from "./RootNavigator.types";
import ForgotPassword from "../signIn/ForgotPassword";
import SetPassword from "../signIn/SetPassword";
import SignUp from "../signUp/SignUp";
import VerifyAccount from "../signUp/VerifyAccount";
import useAuthStore from "../../hooks/useAuthStore";
import ProfileInfo from "../onBoarding/ProfileInfo";
import UploadDocuments from "../onBoarding/UploadDocuments";
import Agreements from "../onBoarding/Agreements";
import { UnderReview } from "../onBoarding/UnderReview";
const Stack = createNativeStackNavigator<RootStackParamList>();

const linking = {
  prefixes: ["lonchina://", "https://lonchina.com", "https://*.lonchina.com"],
};

export default function RootNavigator() {
  const { signedInAs } = useAuthStore();

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <>
          <Stack.Screen name={Routes.SIGN_IN} component={SignIn} />
          <Stack.Screen
            name={Routes.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Stack.Screen name={Routes.SET_PASSWORD} component={SetPassword} />
          <Stack.Screen name={Routes.SIGN_UP} component={SignUp} />
          <Stack.Screen
            name={Routes.VERIFY_ACCOUNT}
            component={VerifyAccount}
          />
        </>
      </Stack.Navigator>
    </NavigationContainer>
  );
}
