import React, { useEffect } from "react";
import { Button, VStack } from "native-base";
import validator from "validator";
import { Routes } from "../../utils/constants";
import { RootStackScreenProps } from "../root/RootNavigator.types";
import useValidatedInput from "../../hooks/useValidatedInput";
import FormHeader from "../common/FormHeader";
import FormFooter from "../common/FormFooter";
import Layout from "../common/Layout";
import useAuthStore from "../../hooks/useAuthStore";
import { setItem } from "../../utils/storage";
import useAxios from "../../hooks/useAxios";

const SignIn = ({ navigation }: RootStackScreenProps<Routes.SIGN_IN>) => {
  const [phoneNumber, phoneNumberError, phoneNumberInput] = useValidatedInput({
    placeholder: "Phone number",
    errorMessage: "Invalid phone number.",
    validateFn: (str: string) => validator.isNumeric(str) && str?.length === 9,
    iconName: "phone-outline",
    inputLeftAddon: "+251",
  });
  const [password, passwordError, passwordInput] = useValidatedInput({
    placeholder: "PIN",
    errorMessage: "PIN must be a 6-digit number.",
    validateFn: (str: string) => validator.isNumeric(str) && str?.length === 6,
    inputType: "password",
    iconName: "lock-outline",
    linkOnPress: () => navigation.push(Routes.FORGOT_PASSWORD),
  });

  const { isLoading, request } = useAxios();

  const { setId, setSignedInAs } = useAuthStore();

  useEffect(() => {
    setSignedInAs(undefined);
  }, [setSignedInAs]);

  const signInService = async () => {
    const { data } = await request({
      url: "/auth/signin",
      data: {},
      failureMessages: {
        400: "Invalid email or password.",
        401: "Invalid email or password.",
      },
    });

    if (data) {
      const { jwtToken, payload } = data.idToken;
      const { sub: id, "custom:role": role } = payload;

      setItem("jwtToken", jwtToken);
      setId(id);
      setSignedInAs(role);
    }
  };

  return (
    <Layout>
      <FormHeader title="LONCHINA" subTitle="Sign In" />
      <VStack space={3}>
        {phoneNumberInput}
        {passwordInput}
        <Button
          isLoading={isLoading}
          isDisabled={
            !phoneNumber || !password || phoneNumberError || passwordError
          }
          mt="2"
          onPress={() => {
            signInService();
          }}
        >
          Sign In
        </Button>
        <FormFooter
          message="Don't have an account? "
          linkLabel="Sign Up"
          linkOnPress={() => navigation.push(Routes.SIGN_UP)}
        />
      </VStack>
    </Layout>
  );
};

export default SignIn;
