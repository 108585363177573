import React from "react";
import Root from "./src/components/root/Root";
import { Amplify } from "aws-amplify";
import awsconfig from "./src/aws-exports";

Amplify.configure(awsconfig);

export default function App() {
  return <Root />;
}
