import React, { useCallback, useEffect } from "react";
import { Button, VStack } from "native-base";
import { Routes } from "../../utils/constants";
import validator from "validator";
import useValidatedInput from "../../hooks/useValidatedInput";
import { RootStackScreenProps } from "../root/RootNavigator.types";
import FormFooter from "../common/FormFooter";
import FormHeader from "../common/FormHeader";
import Layout from "../common/Layout";
import useAxios from "../../hooks/useAxios";

const SignUp = ({ navigation }: RootStackScreenProps<Routes.SIGN_UP>) => {
  const [phoneNumber, phoneNumberError, phoneNumberInput] = useValidatedInput({
    placeholder: "Phone Number",
    errorMessage: "Invalid phone number.",
    validateFn: (str: string) => validator.isNumeric(str) && str?.length === 9,
    iconName: "phone-outline",
    inputLeftAddon: "+251",
  });

  const [firstName, firstNameError, firstNameInput] = useValidatedInput({
    placeholder: "First Name",
    errorMessage: "First name is required.",
    iconName: "account-outline",
  });

  const [middleName, middleNameError, middleNameInput] = useValidatedInput({
    placeholder: "Middle Name",
    errorMessage: "Middle name is required.",
    iconName: "account-outline",
  });

  const [lastName, lastNameError, lastNameInput] = useValidatedInput({
    placeholder: "Last Name",
    errorMessage: "Last name is required.",
    iconName: "account-outline",
  });

  const [password, passwordError, passwordInput] = useValidatedInput({
    placeholder: "PIN",
    errorMessage: "PIN must be a 6-digit number.",
    validateFn: (str: string) => validator.isNumeric(str) && str?.length === 6,
    inputType: "password",
    iconName: "lock-outline",
  });

  const validatePinMatching = useCallback(
    (str: string) => str === password,
    [password]
  );

  const [confirmPassword, confirmPasswordError, confirmPasswordInput] =
    useValidatedInput({
      placeholder: "Confirm PIN",
      errorMessage: "PINs don't match.",
      validateFn: validatePinMatching,
      inputType: "password",
      iconName: "lock-outline",
    });

  const { isLoading, request } = useAxios();

  const signUpService = async () => {
    const { error } = await request({
      url: "/auth/signup",
      data: {
        firstName,
        lastName,
        phoneNumber,
        password,
      },
      successMessage: "Successfully sent a verification code to your email.",
      failureMessages: {
        400: "Invalid information.",
        409: "Account already exists.",
      },
    });

    if (!error) navigation.push(Routes.VERIFY_ACCOUNT, { phoneNumber });
  };

  return (
    <Layout>
      <FormHeader title="LONCHINA" subTitle="Sign Up" />
      <VStack space={3}>
        {phoneNumberInput}
        {passwordInput}
        {confirmPasswordInput}
        {firstNameInput}
        {middleNameInput}
        {lastNameInput}
        <Button
          isLoading={isLoading}
          isDisabled={
            !firstName ||
            firstNameError ||
            !middleName ||
            middleNameError ||
            !lastName ||
            lastNameError ||
            !phoneNumber ||
            phoneNumberError ||
            !password ||
            passwordError ||
            !confirmPassword ||
            confirmPasswordError
          }
          mt="2"
          onPress={() => signUpService()}
        >
          Continue
        </Button>
        <FormFooter
          message="Already have an account? "
          linkLabel="Sign In"
          linkOnPress={() => navigation.push(Routes.SIGN_IN)}
        />
      </VStack>
    </Layout>
  );
};

export default SignUp;
