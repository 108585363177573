import { FormControl, Input, Link, Pressable, Text } from "native-base";
import Icon from "../components/common/Icon";
import React, { useState } from "react";
import useValidation from "./useValidation";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface UseValidatedInputOptions {
  placeholder: string;
  errorMessage?: string;
  validateFn?: (arg0: string) => boolean;
  inputType?: "text" | "password";
  linkLabel?: string;
  linkOnPress?: () => void;
  iconName?: keyof typeof MaterialCommunityIcons.glyphMap;
  inputLeftAddon?: string;
}

export default function useValidatedInput(
  options: UseValidatedInputOptions
): [string, boolean, JSX.Element] {
  const {
    placeholder,
    errorMessage,
    validateFn,
    inputType = "text",
    linkLabel,
    linkOnPress,
    iconName,
    inputLeftAddon,
  } = options;
  const [string, setString, error] = useValidation(validateFn);
  const [showInput, setShowInput] = useState(inputType === "text");

  const validatedInput = (
    <FormControl isRequired isInvalid={error}>
      <Input
        placeholder={placeholder}
        type={showInput ? "text" : "password"}
        value={string}
        onChangeText={(text) => setString(text)}
        InputLeftElement={
          <>
            <>
              {iconName ? (
                <Icon name={iconName} size={5} ml="2" mr="2" />
              ) : undefined}
            </>
            <>{inputLeftAddon && <Text>{inputLeftAddon}</Text>}</>
          </>
        }
        InputRightElement={
          inputType === "password" ? (
            <Pressable onPress={() => setShowInput(!showInput)}>
              <Icon
                name={showInput ? "eye-outline" : "eye-off-outline"}
                size={5}
                mr="2"
                color="muted.400"
              />
            </Pressable>
          ) : undefined
        }
      />
      {errorMessage && (
        <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
      )}
      {linkLabel && (
        <Link
          _text={{
            fontSize: "xs",
            fontWeight: "500",
            color: "primary.600",
          }}
          alignSelf="flex-end"
          mt="1"
          onPress={linkOnPress}
        >
          {linkLabel}
        </Link>
      )}
    </FormControl>
  );

  return [string, error, validatedInput];
}
