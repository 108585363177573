import React from "react";
import { Icon as NativeBaseIcon } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export type IconName = keyof typeof MaterialCommunityIcons.glyphMap;

type IconProps = InterfaceIconProps & {
  name: IconName;
};

const Icon = ({ name, ...rest }: IconProps) => {
  return (
    <NativeBaseIcon as={<MaterialCommunityIcons name={name} />} {...rest} />
  );
};

export default Icon;
