export enum Routes {
  SIGN_IN = "signIn",
  SIGN_UP = "signUp",
  SIGN_UP_AS = "signUpAs",
  VERIFY_ACCOUNT = "verifyAccount",
  FORGOT_PASSWORD = "forgotPassword",
  SET_PASSWORD = "setPassword",
  ONBOARDING_PROFILE_INFO = "onBoardingProfileInfo",
  ONBOARDING_UPLOAD_DOC = "onBoardingUploadDoc",
  ONBOARDING_AGREEMENT = "onBoardingAgreement",
  UNDER_REVIEW = "underReview",
}

export enum UserRoles {
  TRAVELER = "TRAVELER",
  PROVIDER_EMPLOYEE = "PROVIDER_EMPLOYEE",
}
