import React, { useCallback } from "react";
import { Button, FormControl, Input, VStack } from "native-base";
import { Routes } from "../../utils/constants";
import { RootStackScreenProps } from "../root/RootNavigator.types";
import validator from "validator";
import useValidatedInput from "../../hooks/useValidatedInput";
import FormHeader from "../common/FormHeader";
import FormFooter from "../common/FormFooter";
import Layout from "../common/Layout";
import Icon from "../common/Icon";
import useAxios from "../../hooks/useAxios";

const SetPassword = ({
  navigation,
  route,
}: RootStackScreenProps<Routes.SET_PASSWORD>) => {
  const { phoneNumber } = route.params || {};

  const [verificationCode, verificationCodeError, verificationCodeInput] =
    useValidatedInput({
      placeholder: "Verification Code",
      errorMessage: "Invalid verification code.",
      validateFn: validator.isNumeric,
      linkLabel: "Resend Verification Code",
      linkOnPress: () => resendVerificationCodeService(),
      iconName: "lock-outline",
    });

  const [newPassword, newPasswordError, newPasswordInput] = useValidatedInput({
    placeholder: "New Password",
    errorMessage: "Password is required.",
    inputType: "password",
    iconName: "lock-outline",
  });

  const validatePasswordMatching = useCallback(
    (str: string) => str === newPassword,
    [newPassword]
  );

  const [confirmPassword, confirmPasswordError, confirmPasswordInput] =
    useValidatedInput({
      placeholder: "Confirm Password",
      errorMessage: "Passwords don't match.",
      validateFn: validatePasswordMatching,
      inputType: "password",
      iconName: "lock-outline",
    });

  const { isLoading, request } = useAxios();

  const setPasswordService = async () => {
    const { error } = await request({
      url: "/auth/reset",
      data: {
        phoneNumber,
        verificationCode,
        newPassword,
      },
      successMessage: "Successfully updated password.",
      failureMessages: {
        400: "Invalid email or verification code.",
      },
    });

    if (!error) navigation.push(Routes.SIGN_IN);
  };

  const resendVerificationCodeService = async () => {
    await request({
      url: "/auth/forgot",
      data: {
        phoneNumber,
      },
      successMessage: "Resent verification code.",
      failureMessages: {
        400: "Invalid email.",
      },
    });
  };

  return (
    <Layout>
      <FormHeader title="LONCHINA" subTitle="Set Password" />

      <VStack space={3}>
        <FormControl isReadOnly={true}>
          <Input
            value={phoneNumber}
            InputLeftElement={
              <Icon name={"email-outline"} size={5} ml="2" mr="2" />
            }
          />
        </FormControl>
        {verificationCodeInput}
        {newPasswordInput}
        {confirmPasswordInput}
        <Button
          isLoading={isLoading}
          isDisabled={
            !verificationCode ||
            verificationCodeError ||
            !newPassword ||
            newPasswordError ||
            !confirmPassword ||
            confirmPasswordError
          }
          mt="2"
          onPress={() => {
            setPasswordService();
          }}
        >
          Set Password
        </Button>
        <FormFooter
          message="Take me back to "
          linkLabel="Sign In"
          linkOnPress={() => navigation.push(Routes.SIGN_IN)}
        />
      </VStack>
    </Layout>
  );
};

export default SetPassword;
