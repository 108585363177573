import React from "react";
import { Button, VStack } from "native-base";
import validator from "validator";
import { Routes } from "../../utils/constants";
import { RootStackScreenProps } from "../root/RootNavigator.types";
import FormFooter from "../common/FormFooter";
import FormHeader from "../common/FormHeader";
import useValidatedInput from "../../hooks/useValidatedInput";
import Layout from "../common/Layout";
import useAxios from "../../hooks/useAxios";

const ForgotPassword = ({
  navigation,
}: RootStackScreenProps<Routes.FORGOT_PASSWORD>) => {
  const [phoneNumber, phoneNumberError, phoneNumberInput] = useValidatedInput({
    placeholder: "Phone number",
    errorMessage: "Phone number is invalid.",
    validateFn: validator.isMobilePhone,
    iconName: "account-outline",
  });

  const { isLoading, request } = useAxios();

  const forgotPasswordService = async () => {
    const { error } = await request({
      url: "/auth/forgot",
      data: { phoneNumber },
      successMessage: "Successfully sent a verification code to your email.",
      failureMessages: {
        400: "Invalid email or verification code.",
      },
    });

    if (!error) navigation.push(Routes.SET_PASSWORD, { phoneNumber });
  };

  return (
    <Layout>
      <FormHeader title="LONCHINA" subTitle="Forgot Password" />

      <VStack space={3}>
        {phoneNumberInput}
        <Button
          isLoading={isLoading}
          isDisabled={!phoneNumber || phoneNumberError}
          mt="2"
          onPress={() => {
            forgotPasswordService();
          }}
        >
          Send Verification
        </Button>
        <FormFooter
          message="Take me back to "
          linkLabel="Sign In"
          linkOnPress={() => navigation.push(Routes.SIGN_IN)}
        />
      </VStack>
    </Layout>
  );
};

export default ForgotPassword;
