import React from "react";
import { Button, FormControl, Input, VStack } from "native-base";
import { Routes } from "../../utils/constants";
import { RootStackScreenProps } from "../root/RootNavigator.types";
import validator from "validator";
import useValidatedInput from "../../hooks/useValidatedInput";
import FormFooter from "../common/FormFooter";
import FormHeader from "../common/FormHeader";
import Layout from "../common/Layout";
import useAxios from "../../hooks/useAxios";
import Icon from "../common/Icon";

const VerifyAccount = ({
  navigation,
  route,
}: RootStackScreenProps<Routes.VERIFY_ACCOUNT>) => {
  const { phoneNumber } = route.params || {};

  const [verificationCode, verificationCodeError, verificationCodeInput] =
    useValidatedInput({
      placeholder: "Verification Code",
      errorMessage: "Invalid verification code.",
      validateFn: validator.isNumeric,
      linkLabel: "Resend Verification Code",
      linkOnPress: () => resendVerificationCodeService(),
      iconName: "lock-outline",
    });

  const { isLoading, request } = useAxios();

  const verifyAccountService = async () => {
    const { error } = await request({
      url: "/auth/confirm",
      data: {
        phoneNumber,
        verificationCode,
      },
      successMessage: "Successfully confirmed account.",
      failureMessages: {
        400: "Invalid email or verification code.",
      },
    });

    if (!error) navigation.push(Routes.SIGN_IN);
  };

  const resendVerificationCodeService = async () => {
    await request({
      url: "/auth/resend",
      data: {
        phoneNumber,
      },
      successMessage: "Resent verification code.",
      failureMessages: {
        400: "Invalid email.",
      },
    });
  };

  return (
    <Layout>
      <FormHeader title="LONCHINA" subTitle="Confirm Account" />
      <VStack space={3}>
        <FormControl isReadOnly={true}>
          <Input
            value={phoneNumber}
            InputLeftElement={
              <Icon name="phone-outline" size="5" ml="2" mr="2" />
            }
          />
        </FormControl>
        {verificationCodeInput}
        <Button
          isLoading={isLoading}
          isDisabled={!verificationCode || verificationCodeError}
          mt="2"
          onPress={() => {
            verifyAccountService();
          }}
        >
          Verify Account
        </Button>
        <FormFooter
          message="Already have an account? "
          linkLabel="Sign In"
          linkOnPress={() => navigation.push(Routes.SIGN_IN)}
        />
      </VStack>
    </Layout>
  );
};

export default VerifyAccount;
