import React, { ReactNode } from "react";
import { Box, useBreakpointValue } from "native-base";

interface LayoutOptions {
  displayContainer: boolean;
  marginHorizontal: number;
  marginLeft: number;
}

const Layout = ({ children }: { children?: ReactNode }) => {
  const { displayContainer, marginHorizontal, marginLeft }: LayoutOptions =
    useBreakpointValue({
      base: {
        displayContainer: false,
        marginHorizontal: 0,
        marginLeft: 0,
      },
      sm: {
        displayContainer: true,
        marginHorizontal: 36,
        marginLeft: 0,
      },
    });

  return !displayContainer ? (
    <Box minW={200} bg="white" size="100%">
      {children}
    </Box>
  ) : (
    <Box bg="blueGray.50" size="100%">
      <Box
        shadow="8"
        mt={100}
        mb={62}
        mr={marginHorizontal}
        ml={marginHorizontal + marginLeft}
        bg="white"
        rounded="lg"
        maxH="80%"
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
